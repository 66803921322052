import "./BottomNav.css";
import { Box, Link, Stack, Typography } from "@mui/material";

export default function BottomNav() {
  return (
    <Box className="BottomNav" alignContent="center">
      <Stack
        direction="row"
        justifyContent='space-between'
        sx={{pr: {xs: 2, sm: 8}, pl: {xs: 2, sm: 8}}}
      >
        <Box>
          <Typography sx={{fontSize: '14px', color: 'white'}}>
            © Auro Rides Inc.
          </Typography>
        </Box>

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Link
            underline="none"
            href={process.env.REACT_APP_INSTAGRAM_URL}
            rel="noopener"
            target="_blank"
            sx={{color: 'white', fontSize: '14px', fontWeight: 500, pr: 3}}
          >
            Instagram
          </Link>

          <Link
            underline="none"
            href={process.env.REACT_APP_CONDITIONS_URL}
            rel="noopener"
            sx={{color: 'white', fontSize: '14px', fontWeight: 500}}
          >
            Condiciones
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
}
