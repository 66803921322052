import { Grid, Stack, Typography, Link } from "@mui/material";
import Item from './Item';

const List = ({ rides }) => {
  return (
    <Stack >
      <Typography variant="caption" sx={{mb: 3, color: 'grey'}} textAlign="center">
        Al reservar el viaje, declaro haber leído y aceptado los{' '}
        <Link
          underline="none"
          href={process.env.REACT_APP_CONDITIONS_URL}
          rel="noopener"
          target="_blank"
        >
          términos y condiciones
        </Link>
        .
      </Typography>

      <Grid container direction="column" spacing={2}>
        {rides.map((row) => (
          <Grid item key={row.attributes.id}>
            <Item ride={row.attributes} />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default List;
