import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import Tooltip from '@mui/material/Tooltip';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import PeopleIcon from '@mui/icons-material/People';
import { SvgIcon } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Features = ({ ride }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
      <Stack direction="row" spacing={1} alignItems="center">
        {
          ride.vehicle_type === "carro" ?
            <Tooltip title="Carro">
              <SvgIcon className="RideItem__VehicleIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M16,6l3,4h2c1.11,0,2,0.89,2,2v3h-2c0,1.66-1.34,3-3,3s-3-1.34-3-3H9c0,1.66-1.34,3-3,3s-3-1.34-3-3H1v-3c0-1.11,0.89-2,2-2
                        l3-4H16 M10.5,7.5H6.75L4.86,10h5.64V7.5 M12,7.5V10h5.14l-1.89-2.5H12 M6,13.5c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5
                        s1.5-0.67,1.5-1.5S6.83,13.5,6,13.5 M18,13.5c-0.83,0-1.5,0.67-1.5,1.5s0.67,1.5,1.5,1.5s1.5-0.67,1.5-1.5S18.83,13.5,18,13.5z"
                  />
                </svg>
              </SvgIcon>
            </Tooltip> : ''
        }
        {
          ride.vehicle_type === "camioneta" ?
            <Tooltip title="Camioneta">
              <SvgIcon className="RideItem__VehicleIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M16,6H10.5v4H1v5H3a3,3,0,0,0,6,0h6a3,3,0,0,0,6,0h2V12a2,2,0,0,0-2-2H19L16,6M12,7.5h3.5l2,2.5H12V7.5m-6,6A1.5,1.5,0,1,1,4.5,15,1.5,1.5,0,0,1,6,13.5m12,0A1.5,1.5,0,1,1,16.5,15,1.5,1.5,0,0,1,18,13.5Z"
                  />
                </svg>
              </SvgIcon>
            </Tooltip> : ''
        }
        {
          ride.vehicle_type === "van" ?
            <Tooltip title="Van">
              <AirportShuttleIcon className="RideItem__VehicleIcon" />
            </Tooltip> : ''
        }
        {
          ride.vehicle_type === "bus" ?
            <Tooltip title="Bus">
              <AirportShuttleIcon className="RideItem__VehicleIcon" />
            </Tooltip> : ''
        }
        <AccountCircleIcon className="RideItem__VehicleIcon" />
        <Typography gutterBottom align='center' className="RideItem__SecondaryText RideItem__DriverName">
          {ride.driver_first_name} {ride.driver_last_name}
        </Typography>
        <Divider sx={{ height: 20, m: 0.5, display: { xs: 'none', sm: 'block' } }} orientation="vertical" />
        {
          ride.air_conditioning ?
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
              <Tooltip title="AC">
                <AcUnitIcon className="RideItem__SecondaryIcon" />
              </Tooltip>
              <Typography
                gutterBottom
                align='center'
                className="RideItem__SecondaryText"
                display={{ xs: "none", sm: "block" }}
              >
                Aire acondicionado
              </Typography>
            </Stack> : ''
        }
        <Tooltip title="Asientos">
          <PeopleIcon className="RideItem__SecondaryIcon" />
        </Tooltip>
        <Typography align='center' className="RideItem__SecondaryText">
          {ride.pax_availability}
        </Typography>
        <Typography align='center' className="RideItem__SecondaryText" display={{ xs: "none", sm: "block" }}>
          asientos disponibles
        </Typography>
      </Stack>
    </Stack>
  );
}

export default Features;
