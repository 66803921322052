import { Autocomplete } from "@mui/material";
import { useState } from "react";

const APIAutocomplete = ({
  sx,
  location,
  setLocation,
  renderInput
}) => {
  const [inputValue, setInputValue] = useState('');
  const [placePredictions, setPlacePredictions] = useState([]);

  const handleOnInputChange = (_, newVal) => {
    if (newVal.trim() === '') {
      setLocation({});
    }

    setInputValue(newVal);
  }

  const fetchLocation = (loc) => {
    if (!loc) {
      return;
    }

    setLocation(loc);
  }

  const getPlacePredictions = async (name) => {
    if (name === '') {
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/places?search=${name}`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok');
      })
      .then((result) => {
        setPlacePredictions(result.data);
      }).catch((err) => {
        console.log(err);
      });
  }

  let places = placePredictions.map((p) => {
    return {
      id: p.id,
      label: p.attributes.human_address,
      geo: {
        lat: p.attributes.lat,
        lng: p.attributes.lng
      }
    };
  });

  return (
      <Autocomplete
        freeSolo // Setting freeSolo mode prevents popup "no options" when user starts typing.
        disableClearable
        sx={sx}
        noOptionsText="No hemos encontrado este lugar"
        className="SearchInput"
        options={places}
        value={location}
        inputValue={inputValue}
        getOptionLabel={(opt) => { return opt.label || '' }}
        onKeyUp={(evt) => getPlacePredictions(evt.target.value)}
        onChange={(_, val) => fetchLocation(val)}
        onInputChange={handleOnInputChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={renderInput}
      />
  );
}

export default APIAutocomplete;
