import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { CardActionArea, Chip } from '@mui/material';
import RouteIcon from '@mui/icons-material/Route';
import PlaceIcon from '@mui/icons-material/Place';

import Route from './item/Route';
import Features from './item/Features';

const Item = ({ ride }) => {
  const AURORIDES_COMMISSION_PERCENTAGE = 0.1;

  const stops = ride.stops.slice(1, ride.stops.length - 1);
  const totalPrice = ride.price + Math.ceil(ride.price * AURORIDES_COMMISSION_PERCENTAGE);

  let currency = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: ride.currency
  });

  // Numbers of stops = stops - departure - arrival
  const stopsLabel = "Paradas: " + (ride.stops.length - 2);

  return (
    <Card className="RideItem" variant="outlined" sx={{':hover': { boxShadow: 4 }}}>
      <CardActionArea href={ride.book_url} target="_blank">
        <CardContent sx={{p: 3}}>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Route ride={ride} />
            <Stack alignItems="center">
              <Typography className="RideItem__Price">
                {currency.format(totalPrice / 100)}
              </Typography>
              <Typography variant="caption" sx={{fontSize: 10}}>
                * por persona
              </Typography>
            </Stack>
          </Stack>
          <Chip
            icon={<RouteIcon />}
            label={stopsLabel}
            variant="outlined" sx={{mt: 2}}
          />
          {
            stops.length > 0 ?
              <Stack sx={{mt: 1}}>
                {stops.map((stop) => (
                  <Stack direction="row" key={stop.id} alignItems="center" sx={{mt: 1}}>
                    <PlaceIcon align='center' className="RideItem__StopIcon" sx={{mr: 0.5}} />
                    <Typography className="RideItem__Stop">{stop.place.human_address}</Typography>
                  </Stack>
                ))}
              </Stack> : ''
          }
          <Divider sx={{mb: 2, mt: 2}} />
          <Features ride={ride} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default Item;
