import utc from 'dayjs/plugin/utc';
import timezone from "dayjs/plugin/timezone";
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';

import { DEFAULT_TIMEZONE } from '../../../../config';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

const Route = ({ ride }) => {
  const departureAddr = ride.stops[0].place.human_address;
  const arrivalAddr = ride.stops[ride.stops.length - 1].place.human_address;

  const departureTimeInTimezone = dayjs(ride.departure_time).tz(DEFAULT_TIMEZONE);
  const arrivalTimeInTimezone = dayjs(ride.arrival_time).tz(DEFAULT_TIMEZONE);
  const timeDiff = dayjs.duration(arrivalTimeInTimezone.diff(departureTimeInTimezone)).format('HH[h] mm');

  const formatAddress = (addr) => addr.replace(', Venezuela', '');

  console.log(departureTimeInTimezone.hour());

  let appxDepartureTimeLabel;
  if (departureTimeInTimezone.hour() >= 1 && departureTimeInTimezone.hour() < 12) {
    appxDepartureTimeLabel = 'Por la mañana';
  } else if (departureTimeInTimezone.hour() >= 12 && departureTimeInTimezone.hour() < 19) {
    appxDepartureTimeLabel = 'Por la tarde';
  } else {
    appxDepartureTimeLabel = 'Por la noche';
  }

  const horizTripLine = <Box className="TripLineHorizontal" sx={{ mt: 10, display: { xs: 'none', sm: 'block' } }} />;

  debugger;
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction={{ sx: "column", sm: "row" }} spacing={2} alignItems={{ sx: "center", sm: "end" }}>
        <Stack direction="column" spacing={2}>
          {
            ride.flexible_departure_time ? (
              <Typography variant="body1" fontWeight="600" fontSize={18}>
                {appxDepartureTimeLabel}
              </Typography>
            ) : (
              <Typography variant="body1" fontWeight="600" fontSize={18}>
                {departureTimeInTimezone.format('hh:mm A')}
              </Typography>
            )
          }
          <Typography variant="body2" sx={{ maxWidth: '200px' }}>
            {formatAddress(departureAddr)}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignContent="center" alignItems="center">
          {horizTripLine}
          <Typography
            sx={{ width: 50, fontSize: 14, display: { xs: 'none', sm: 'block' } }}
            gutterBottom
            className="RideItem__SecondaryText"
          >
            {timeDiff}
          </Typography>
          {horizTripLine}
        </Stack>

        {/* BEGIN: Mobile Only */}
        <Box className="TripLineVertical" sx={{ mt: 1, display: { xs: 'block', sm: 'none' } }} />
        <Typography
          sx={{ width: 50, fontSize: 14, mt: 1, mb: 1, display: { xs: 'block', sm: 'none' } }}
          className="RideItem__SecondaryText"
        >
          {timeDiff}
        </Typography>
        <Box className="TripLineVertical" sx={{ mb: 1, display: { xs: 'block', sm: 'none' } }} />
        {/* END: Mobile Only */}

        <Stack direction="column" spacing={2}>
          {
            !ride.flexible_departure_time && (
              <Typography variant="body1" fontWeight="600" fontSize={18}>
                {arrivalTimeInTimezone.format('hh:mm A')}
              </Typography>
            )
          }
          <Typography variant="body2">
            {formatAddress(arrivalAddr)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Route;
