import 'dayjs/locale/es';
import dayjs from 'dayjs';
import './New.css';
import { useState } from 'react';
import { Typography, MenuItem, Select, Grid, TextField, FormControl, InputLabel, Container, Checkbox, FormControlLabel, InputAdornment, OutlinedInput, Stack, Divider, Box, Alert, Link } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { esES } from '@mui/x-date-pickers/locales';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PeopleIcon from '@mui/icons-material/People';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SendIcon from '@mui/icons-material/Send';
import ReCAPTCHA from 'react-google-recaptcha';
import googleAPIConfig from "../../../googleAPIConfig";
import { DEFAULT_TIMEZONE } from '../../../config';

import { PublishButton } from '../../common/Button';
import Nav from '../../common/Nav';
import LocationAutocomplete from '../../common/LocationAutocomplete';

const NONE_REQUEST_STATUS = 'none';
const SUCCESS_REQUEST_STATUS = 'success';
const ERROR_REQUEST_STATUS = 'error';
const DEFAULT_VEHICLE_TYPE = 'carro';
const CURRENCY = 'USD'; // at the moment we only support USD.

export default function New() {
  const dt = dayjs().endOf('hour').add(1, 'minute');

  const [departureTime, setDepartureTime] = useState(dt);
  const [departureTimeError, setDepartureTimeError] = useState(false);
  const [arrivalTime, setArrivalTime] = useState(dt.add(2, 'hour'));
  const [arrivalTimeError, setArrivalTimeError] = useState(false);
  const [whatsappPhoneNumber, setWhatsappPhoneNumber] = useState('');
  const [whatsappPhoneNumberError, setWhatsappPhoneNumberError] = useState(false);
  const [driverFirstName, setDriverFirstName] = useState('');
  const [driverFirstNameError, setDriverFirstNameError] = useState(false);
  const [driverLastName, setDriverLastName] = useState('');
  const [driverLastNameError, setDriverLastNameError] = useState(false);
  const [vehicleType, setVehicleType] = useState(DEFAULT_VEHICLE_TYPE);
  const [paxAvailability, setPaxAvailability] = useState('');
  const [paxAvailabilityError, setPaxAvailabilityError] = useState(false);
  const [airConditioning, setAirConditioning] = useState(false);
  const [price, setPrice] = useState('');
  const [priceError, setPriceError] = useState(false);
  const [currency, _] = useState(CURRENCY);
  const [captchaStatus, setCaptchaStatus] = useState(false);

  // Stops: we will merge main stops with secondaries upon making request
  const [locationFrom, setLocationFrom] = useState({});
  const [locationFromError, setLocationFromError] = useState(false);
  const [locationTo, setLocationTo] = useState({});
  const [locationToError, setLocationToError] = useState(false);

  const [stop1, setStop1] = useState({});
  const [stop2, setStop2] = useState({});
  const [stop3, setStop3] = useState({});
  const [stop4, setStop4] = useState({});

  const [requestStatus, setRequestStatus] = useState(NONE_REQUEST_STATUS);

  const handleDepartureTimeChange = (departureTime) => {
    setDepartureTime(departureTime);
    setDepartureTimeError(!departureTime);

    if (departureTime && arrivalTime) {
      setArrivalTimeError(arrivalTime < departureTime);
    }
  };

  const handleArrivalTimeChange = (arrivalTime) => {
    setArrivalTime(arrivalTime);

    if (departureTime && arrivalTime) {
      setArrivalTimeError(arrivalTime < departureTime);
    }
  };

  const handleWhatsappPhoneNumberChange = e => {
    setWhatsappPhoneNumber(e.target.value);
    setWhatsappPhoneNumberError(!e.target.validity.valid);
  };

  const handleDriverFirstNameChange = e => {
    setDriverFirstName(e.target.value);
    setDriverFirstNameError(!e.target.validity.valid);
  };

  const handleDriverLastNameChange = e => {
    setDriverLastName(e.target.value);
    setDriverLastNameError(!e.target.validity.valid);
  };

  const handlePaxAvailabilityChange = e => {
    setPaxAvailability(e.target.value);
    setPaxAvailabilityError(!e.target.validity.valid);
  };

  const handlePriceChange = e => {
    setPrice(e.target.value);
    setPriceError(!e.target.validity.valid);
  };

  const handleLocationFromChange = (locationFrom) => {
    setLocationFrom(locationFrom);
    setLocationFromError(!locationFrom.geo);
  };

  const handleLocationToChange = (locationTo) => {
    setLocationTo(locationTo);
    setLocationToError(!locationTo.geo);
  };

  const handleCaptchaChange = () => {
    setCaptchaStatus(true);
  };

  const handleCaptchaExpired = () => {
    setCaptchaStatus(false);
  };

  const publishIsDisabled = () => {
    return (
      !departureTime ||
      !arrivalTime ||
      !whatsappPhoneNumber ||
      !driverFirstName ||
      !driverLastName ||
      !vehicleType ||
      !paxAvailability ||
      !price ||
      !captchaStatus ||
      !locationFrom.geo ||
      !locationTo.geo
    );
  }

  const resetForm = () => {
    setDepartureTime(dt);
    setDepartureTimeError(false);

    setArrivalTime(dt.add(2, 'hour'));
    setArrivalTimeError(false);

    setPaxAvailability('');
    setPaxAvailabilityError(false);

    setAirConditioning(false);

    setPrice('');
    setPriceError(false);

    setLocationFrom({});
    setLocationFromError(false);

    setLocationTo({});
    setLocationToError(false);

    setStop1({});
    setStop2({});
    setStop3({});
    setStop4({});

    setCaptchaStatus(false);
    setRequestStatus(NONE_REQUEST_STATUS);
  }

  const createRide = async (e) => {
    let rideStops = [
      locationFrom,
      stop1,
      stop2,
      stop3,
      stop4,
      locationTo
    ]
    .filter((place) => !!place.geo) // ignore unset places
    .map((place, idx) => ({
        place_id: place.id,
        order: idx,
      })
    )

    let payload = {
      ride: {
        departure_time: departureTime,
        arrival_time: arrivalTime,
        whatsapp_phone_number: whatsappPhoneNumber,
        driver_first_name: driverFirstName,
        driver_last_name: driverLastName,
        vehicle_type: vehicleType,
        pax_availability: paxAvailability,
        air_conditioning: airConditioning,
        price: price * 100,
        currency: currency,
        stops: rideStops
      }
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/v1/rides`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok');
      })
      .then((_) => {
        setRequestStatus(SUCCESS_REQUEST_STATUS);
      }).catch((_) => {
        setRequestStatus(ERROR_REQUEST_STATUS);
      });
  }

  const form = (
    <Container maxWidth="md" className="Form">
      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 4}}>
        <Grid item sm={6} xs={12}>
          <LocationAutocomplete
            location={locationFrom}
            setLocation={handleLocationFromChange}
            renderInput={
              (params) =>
              <TextField
                {...params}
                label="¿Desde dónde sales? *"
                placeholder="Lechería, Anzoátegui"
                InputLabelProps={{ shrink: true }}
                error={locationFromError}
              />
            }
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <LocationAutocomplete
            location={locationTo}
            setLocation={handleLocationToChange}
            renderInput={
              (params) =>
              <TextField
                {...params}
                label="¿A dónde vas? *"
                placeholder="Caracas, Distrito Capital"
                InputLabelProps={{ shrink: true }}
                error={locationToError}
              />
            }
          />
        </Grid>

        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={'es-ES'}
            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        >
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <DateTimePicker
                timezone={DEFAULT_TIMEZONE}
                label="Fecha de salida *"
                format='DD MMMM HH:m'
                ampm={false}
                disablePast={true}
                value={departureTime}
                onChange={(e) => handleDepartureTimeChange(e)}
                slotProps={{
                  textField: {
                    error: departureTimeError,
                    InputLabelProps: { shrink: true },
                  },
                }}
              />
            </FormControl>
          </Grid>

          <Grid item sm={6} xs={12}>
            <FormControl fullWidth>
              <DateTimePicker
                timezone={DEFAULT_TIMEZONE}
                label="Fecha de llegada *"
                format='DD MMMM HH:m'
                ampm={false}
                disablePast={true}
                minDateTime={departureTime}
                value={arrivalTime}
                onChange={(e) => handleArrivalTimeChange(e)}
                slotProps={{
                  textField: {
                    error: arrivalTimeError,
                    InputLabelProps: { shrink: true },
                  },
                }}
              />
            </FormControl>
          </Grid>
        </LocalizationProvider>

        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Nombre del conductor"
              placeholder="Jose"
              value={driverFirstName}
              onChange={(e) => handleDriverFirstNameChange(e)}
              error={driverFirstNameError}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Apellidos del conductor"
              placeholder="Pérez"
              value={driverLastName}
              onChange={(e) => handleDriverLastNameChange(e)}
              error={driverLastNameError}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Numero de telefono en WhatsApp"
              inputProps={{ pattern: "04\\d{9}" }}
              placeholder="04xxxxxxxxx"
              value={whatsappPhoneNumber}
              onChange={(e) => handleWhatsappPhoneNumberChange(e)}
              error={whatsappPhoneNumberError}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <WhatsAppIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel id="vehicle-type">Tipo de vehículo *</InputLabel>
            <Select
              id="vehicle-type"
              label="Tipo de vehículo *"
              value={vehicleType}
              onChange={(e) => setVehicleType(e.target.value)}
            >
              <MenuItem value={"carro"}>Carro</MenuItem>
              <MenuItem value={"camioneta"}>Camioneta</MenuItem>
              <MenuItem value={"van"}>Van</MenuItem>
              <MenuItem value={"bus"}>Bus</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              label="¿Cuántos pasajeros puedes llevar?"
              type="number"
              inputProps={{ min: 1 }}
              placeholder="3"
              value={paxAvailability}
              onChange={(e) => handlePaxAvailabilityChange(e)}
              error={paxAvailabilityError}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PeopleIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>

        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="outlined-adornment-price">Precio por asiento *</InputLabel>
            <OutlinedInput
              id="outlined-adornment-price"
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              type="number"
              inputProps={{ min: 1, step: 0.01 }}
              label="Precio por asiento *"
              placeholder="6.99"
              value={price}
              onChange={(e) => handlePriceChange(e)}
              error={priceError}
              required
            />
          </FormControl>
        </Grid>

        <Grid item sm={12}>
          <Typography variant="button" gutterBottom>
            Paradas en Ruta (Opcional)
          </Typography>
          <br/>
          <Typography variant="caption" gutterBottom sx={{color: 'grey'}}>
            Agregar paradas puede atraer a más pasajeros para tu viaje.
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <LocationAutocomplete
            location={stop1}
            setLocation={setStop1}
            renderInput={
              (params) =>
              <TextField {...params} label="Parada 1" />
            }
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <LocationAutocomplete
              location={stop3}
              setLocation={setStop3}
              renderInput={
                (params) =>
                <TextField {...params} label="Parada 3" />
              }
            />
        </Grid>

        <Grid item sm={6} xs={12}>
          <LocationAutocomplete
            location={stop2}
            setLocation={setStop2}
            renderInput={
              (params) =>
              <TextField {...params} label="Parada 2" />
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <LocationAutocomplete
            location={stop4}
            setLocation={setStop4}
            renderInput={
              (params) =>
              <TextField {...params} label="Parada 4" />
            }
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Typography variant="button" gutterBottom>
            Características del Vehículo
          </Typography>
          <FormControlLabel
              control={
                <Checkbox
                  value={airConditioning}
                  onChange={(e) => setAirConditioning(e.target.checked) }
                />
              }
              label="Aire acondicionado"
            />
        </Grid>
        <Grid item sm={6} xs={12}></Grid>

        <Grid item xs={12}>
          <Stack alignItems="center">
            <ReCAPTCHA
              sitekey={googleAPIConfig.siteKey}
              onChange={handleCaptchaChange}
              onExpired={handleCaptchaExpired}
            />
          </Stack>
        </Grid>
      </Grid>

      <Divider sx={{mt: 2, mb: 4}} />

      {
        requestStatus === ERROR_REQUEST_STATUS ?
          <Alert
            sx={{mb: 2}}
            severity="error"
            variant="outlined"
            onClose={() => setRequestStatus(NONE_REQUEST_STATUS)}
          >
            Algo salió mal. Por favor, revisa todos los campos y intenta de nuevo.
          </Alert> : ''
      }

      <Stack alignItems="center">
        <PublishButton
          onClick={createRide}
          variant="contained"
          sx={{mb: 2, minWidth: 120, textTransform: 'none'}}
          disabled={publishIsDisabled()}
          endIcon={<SendIcon/>}
        >
          <Typography fontWeight={700} fontSize={18}>Publicar</Typography>
        </PublishButton>

        <Typography variant="caption" sx={{color: 'grey'}} textAlign="center">
          Al publicar el viaje, declaro haber leído y aceptado los{' '}
          <Link
            underline="none"
            href={process.env.REACT_APP_CONDITIONS_URL}
            rel="noopener"
            target="_blank"
          >
            términos y condiciones
          </Link>
          .
        </Typography>
      </Stack>
    </Container>
  );

  const successAlert = (
    <Alert
      sx={{mb: 2}}
      severity="success"
      variant="outlined"
      onClose={() => resetForm()}
    >
      ¡Todo listo! Puedes cerrar este mensaje y crear un nuevo viaje.
    </Alert>
  );

  return (
    <Box sx={{mb: 5}}>
      <Nav />

      <Stack
        direction='column'
        alignContent="center"
        alignItems="center"
        sx={{pt: 6, pb: 6}}
      >

        <Typography fontWeight={600} sx={{pb: 5, fontSize: { xs: 22, sm: 30 }}}>
          Publica tu proximo viaje
        </Typography>

        {requestStatus === SUCCESS_REQUEST_STATUS ? successAlert : form }
      </Stack>
    </Box>
  )
}
